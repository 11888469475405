import gql from "graphql-tag";
export const GET_ALL_NOTIFICATIONS = gql`
  query getNotifications($limit: Int, $skip: Int) {
    getNotifications(limit: $limit, skip: $skip) {
      nodes {
        id
        message
        title
        kind
        readed
        readedByAdmin
        createdAt
      }
      count
    }
  }
`;
export const ALL_NOTIFICATIONS = gql`
  query getAllNotifications($limit: Int, $skip: Int) {
    getAllNotifications(limit: $limit, skip: $skip) {
      nodes {
        id
        message
        title
        kind
        readed
        readedByAdmin
        createdAt
      }
      count
    }
  }
`;
export const NOTIFICATION_ADDEDD = gql`
  subscription notificationAdded($id: ID!) {
    notificationAdded(id: $id) {
      id
      message
      readed
      createdAt
      kind
      title
      readedByAdmin
    }
  }
`;
export const READ_ALL_NOTIFICATION = gql`
  mutation {
    readAllNotification
  }
`;
export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      id
    }
  }
`;
export const DELETE_All_NOTIFICATIONS = gql`
  mutation deleteAllNotifications($ids: [String]) {
    deleteAllNotifications(ids: $ids)
  }
`;
