import gql from "graphql-tag";
const FRAGMENT_SETTINGS = `  delivery {
  deliveriesApiKeys {
    stuart {
      apiClientId
      apiSecret
      token
      onlyByAdmin
    }
    yper {
      apiClientId
      apiSecret
      token
      onlyByAdmin
    }
  }
}
stripe {
  publicKey
  privateKey
  status
  clientId
}
paypal {
  publicKey
  privateKey
  status
  clientId
}
mailConfig {
  mailHost
  mailPort
  mailEncryption
  userName
  mailPassword
  senderEmail
  senderName
}
isMarketPlace
showTelStoreInClientApp
showAddressStoreInClientApp
authorizeFreeDelivery
authorizeExpressDelivery
authorizeBannerAdsForStore
paymentCash
paymentStripe
paymentPaypal
stripeRequired
paypalRequired
cashRequired
appName
appLogo
appResponsiveLogo
authorizeChatDirect
authorizeDeleteSubCommentNotice
authorizeDeleteNotice
authorizeNoticeStore
auhorizeNoticeAutomaticly
limitNoticeSubComments
authorizeNoticeProduct
authorizeReplayOtherNotice
updateOrderPriceWhenUpdateOrderProducts
uniqueStore
authorizeCopyProductOnImport
authorizeDuplicateProducts
productsDocuments {
  required
  hidden
}
defaultStoreLocation {
  latitude
  longitude
  address
}
defaultStorePassword
authorizeStuart
authorizeYper
authorizeVerifyInscription
templateMail {
  header
  footer
}
oneSignalConfig {
  oneSignalClientAppId
  oneSignalClientRestApi
  oneSignalChannelId
  oneSignalChannelIdChat
  oneSignalChannelIdNotification

  oneSignalProId
  oneSignalProChannelId
  oneSignalProChannelIdChat
  oneSignalProChannelIdNotification
  oneSignalProRestApi
}

supportContact {
  supportTelClient
  supportEmailClient
  supportEmailPro
  supportTelPro
  supportMailSubscribeStore
}

authorizeUpdateMentionLegal
authorizeShowGroupsInClientApp
authorizePrinter
authorizeAuthentificationFacebook`;
export const ADD_COUNTRY = gql`
  mutation addCountry($input: InputCountry) {
    addCountry(input: $input) {
      id
      name
      code
      phone
      language
      currency
      emoji
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation deleteCountry($id: ID) {
    deleteCountry(id: $id) {
      id
      name
      code
      phone
      language
      currency
    }
  }
`;
export const GET_COUNTRIES = gql`
  query {
    getCountries {
      id
      name
      code
      phone
      language
      currency
      emoji
    }
  }
`;
export const UPDATE_SETTINGS = gql`
  mutation updateDeliveryApiSetting($input: DeliveryApiKeyInput) {
    updateDeliveryApiSetting(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_PAYMENT_KEYS = gql`
  mutation updatePaymentKeysApi($input: PaymentApiKeysInput) {
    updatePaymentKeysApi(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_SETTINGS_PARAMS = gql`
  mutation updateSettingsParams($input: ParamsInput!) {
    updateSettingsParams(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_GLOBAL_SETTINGS = gql`
  mutation updateGlobalSettings($input: GlobalInput) {
    updateGlobalSettings(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_TEMPLATE_MAIL_SETTINGS = gql`
  mutation updateMailTemplateCore($input: TemplateMailCoreInput) {
    updateMailTemplateCore(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_ONE_SIGNAL_SETTINGS = gql`
  mutation updateOneSignalConfig($input: OneSignalConfigInput) {
    updateOneSignalConfig(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const UPDATE_CONTACT_SUPPORT_SETTINGS = gql`
  mutation updateSupportContact($input: SupportContactInput) {
    updateSupportContact(input: $input) {
      ${FRAGMENT_SETTINGS}
    }
  }
`;
export const GET_APP_SETTINGS = gql`
  query {
    getSettings {
      ${FRAGMENT_SETTINGS}
    }

 
  }
`;
export const GET_STEPS_SETTINGS = gql`
  query {
    getProfileCompletedConfiguration {
      isDeliveryModeCompleted
      isPaymentMethodCompleted
      isCategoryCompleted
      isProductCompleted
      isProfileCompleted
      completedProfilePourcent
      isFullCompleted
    }
  }
`;

export const GET_SETTINGS = gql`
  query {
    myStore {
      id
      settings {
        paymentCash
        paymentPaypal
        paymentStripe
      }
    }
  }
`;
