import gql from "graphql-tag";
export const GET_PUSH_NOTIFICATIONS = gql`
  query getPushNotifications($skip: Int!, $limit: Int!) {
    getPushNotifications(skip: $skip, limit: $limit) {
      nodes {
        id
        title
        message
        notificationId
        includedTags
        excludedTags
        scheduled
        status
        createdAt
        completedAt
        action
        successful
        remaining
        scheduledAfter
      }
      lastPage
    }
  }
`;
export const GET_PUSH_NOTIFICATION = gql`
  query getPushNotification($id: String!) {
    getPushNotification(id: $id) {
      id
      title
      message
      notificationId
      includedTags
      excludedTags
      scheduled
      status
      createdAt
      completedAt
      action
      successful
      remaining
      scheduledAfter
    }
  }
`;

export const CANCEL_PUSH_NOTIFICATIONS = gql`
  mutation cancelPushNotification($pushNotificationId: String!) {
    cancelPushNotification(pushNotificationId: $pushNotificationId)
  }
`;

export const ADD_PUSH_NOTIFICATIONS = gql`
  mutation addPushNotification($pushNotificationInput: PushNotificationInput!) {
    addPushNotification(pushNotificationInput: $pushNotificationInput)
  }
`;
export const EDIT_PUSH_NOTIFICATIONS = gql`
  mutation updatePushNotification(
    $id: String
    $pushNotificationInput: PushNotificationInput!
  ) {
    updatePushNotification(
      id: $id
      pushNotificationInput: $pushNotificationInput
    )
  }
`;
export const GET_NOTIFICATIONS_TEMPLATES = gql`
  query {
    getTemplateNotifications {
      id
      name
      templateName
    }
  }
`;
export const GET_SMS_TEMPLATES = gql`
  query {
    getAllTemplatesSms {
      id
      name
      templateName
    }
  }
`;

export const GET_SMS_TEMPLATE = gql`
  query getTemplatesSms($id: ID!) {
    getTemplatesSms(id: $id) {
      id
      name
      templateName
      defaultSubject
      defaultContent
      params
      translations {
        country
        subject
        content
        description
      }
    }
  }
`;

export const GET_NOTIFICATION_TEMPLATE = gql`
  query getTemplateNotification($id: ID!) {
    getTemplateNotification(id: $id) {
      id
      name
      templateName
      defaultSubject
      defaultContent
      params
      translations {
        country
        subject
        content
        description
      }
    }
  }
`;
export const UPDATE_TEMPLATE_NOTIFICATION = gql`
  mutation updateClientNotificationTemplate($id: ID, $input: MailInputUpdate) {
    updateClientNotificationTemplate(id: $id, input: $input) {
      id
    }
  }
`;
export const UPDATE_TEMPLATE_SMS = gql`
  mutation updateTemplateSms($id: ID, $input: MailInputUpdate) {
    updateTemplateSms(id: $id, input: $input) {
      id
    }
  }
`;
