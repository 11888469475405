import { useMutation, useQuery } from "react-apollo";
import {
  ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_ALL_NOTIFICATIONS,
  NOTIFICATION_ADDEDD,
  READ_ALL_NOTIFICATION,
  DELETE_All_NOTIFICATIONS,
} from "graphql/tags/notification.tag";
import Token from "configs/Token";
import uniqBy from "lodash/uniqBy";
import gql from "graphql-tag";
import { isAdmin } from "configs/function";
export const useMutationNotifcation = () => {
  const [readAll] = useMutation(READ_ALL_NOTIFICATION);
  const [deleteAll] = useMutation(DELETE_All_NOTIFICATIONS);
  const [deleteN] = useMutation(DELETE_NOTIFICATION);
  function deleteAllNotifications({ ids }) {
    return deleteAll({
      variables: {
        ids,
      },
      update: (cache, {}) => {
        const FRAG = gql`
          fragment notif on AllNotificationConnection {
            nodes {
              id
              message
              title
              kind
              readed
              createdAt
              readedByAdmin
            }
            count
          }
        `;
        let data = cache.readFragment({
          id: "AllNotificationConnection",
          fragment: FRAG,
        });
        data.nodes = data?.nodes?.filter((notif) => {
          return !!!ids.includes(notif?.id);
        });
        data.count = data.count - ids?.length;
        cache.writeFragment({
          id: "AllNotificationConnection",
          fragment: FRAG,
          data,
        });
      },
    });
  }
  function deleteNotification(id) {
    return deleteN({
      variables: {
        id,
      },
      update: (cache, {}) => {
        const FRAG = gql`
          fragment notif on AllNotificationConnection {
            nodes {
              id
              message
              title
              kind
              readed
              createdAt
              readedByAdmin
            }
            count
          }
        `;
        let data = cache.readFragment({
          id: "AllNotificationConnection",
          fragment: FRAG,
        });
        data.nodes = data?.nodes?.filter((notif) => {
          return notif?.id !== id;
        });
        data.count = data?.count - 1;
        cache.writeFragment({
          id: "AllNotificationConnection",
          fragment: FRAG,
          data,
        });
      },
    });
  }
  function readAllNotifs() {
    return readAll({
      update: (cache, {}) => {
        const FRAG = gql`
          fragment notif on NotificationConnection {
            nodes {
              id
              message
              title
              kind
              readed
              createdAt
              readedByAdmin
            }
            count
          }
        `;
        let data = cache.readFragment({
          id: "NotificationConnection",
          fragment: FRAG,
        });
        data.nodes = data?.nodes?.map((notif) => {
          if (isAdmin()) {
            notif.readedByAdmin = true;
          } else {
            notif.readed = true;
          }

          return notif;
        });
        cache.writeFragment({
          id: "NotificationConnection",
          fragment: FRAG,
          data,
        });
      },
    });
  }
  return { readAllNotifs, deleteNotification, deleteAllNotifications };
};
export const useAllNotificationsQuery = ({ skip, limit }) => {
  const { data, loading, subscribeToMore, refetch } = useQuery(
    ALL_NOTIFICATIONS,
    {
      variables: {
        limit,
        skip,
      },
    }
  );
  function subscribeToMoreNotification() {
    subscribeToMore({
      document: NOTIFICATION_ADDEDD,
      variables: { id: Token.getId() },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.notificationAdded;

        return {
          ...prev,
          getAllNotifications: {
            ...prev.getAllNotifications,
            count: 0,
            nodes: uniqBy(
              [newFeedItem, ...prev.getAllNotifications.nodes],
              "id"
            ),
          },
        };
      },
    });
  }

  return {
    notifications:
      data && data.getAllNotifications && data.getAllNotifications.nodes,
    loading,
    count: data && data.getAllNotifications && data.getAllNotifications.count,
    subscribeToMoreNotification,
    refetch,
  };
};
export const useNotificationQuery = (filter) => {
  const { data, loading, subscribeToMore, refetch } = useQuery(
    GET_ALL_NOTIFICATIONS
  );
  function subscribeToMoreNotification() {
    subscribeToMore({
      document: NOTIFICATION_ADDEDD,
      variables: { id: Token.getId() },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.notificationAdded;

        return {
          ...prev,
          getNotifications: {
            ...prev.getNotifications,
            count: 0,
            nodes: uniqBy([newFeedItem, ...prev.getNotifications.nodes], "id"),
          },
        };
      },
    });
  }
  const inReaded =
    data &&
    data.getNotifications &&
    data.getNotifications.nodes &&
    data.getNotifications.nodes.filter((current) =>
      isAdmin() ? current.readedByAdmin === false : current.readed === false
    ).length;
  return {
    notifications: data && data.getNotifications && data.getNotifications.nodes,
    loading,
    count: data && data.getNotifications && data.getNotifications.count,
    subscribeToMoreNotification,
    inReaded,
    refetch,
  };
};
