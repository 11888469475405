import React from "react";
import logo from "assets/img/logo/logo.png";
import "../../../assets/scss/components/app-loader.scss";
import { App } from "configs/constants";
import { Spinner } from "reactstrap";
const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      {/* <img className="fallback-logo" src={App.logo} alt="logo" /> */}
      <div className="loading mt-2">
        <Spinner color="primary" size="large" />
      </div>
    </div>
  );
};

export default SpinnerComponent;
