import gql from "graphql-tag";
export const GET_STORE_INTERRUPTIONS = gql`
  query StoreInterruptions($professional: ID!, $limit: Int!, $skip: Int!) {
    storeInterruptions(
      professional: $professional
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        startDate
        endDate
        reason
        createdAt
      }
      lastPage
    }
  }
`;
