import Token from "./Token";
import lodash from "lodash";
import Resizer from "react-image-file-resizer";
export const stripTypenames = (value) => {
  if (Array.isArray(value)) {
    return value.map(stripTypenames);
  } else if (value !== null && typeof value === "object") {
    const newObject = {};
    for (const property in value) {
      if (property !== "__typename") {
        newObject[property] = stripTypenames(value[property]);
      }
    }
    return newObject;
  } else {
    return value;
  }
};
export const makeId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const removeFirstAndLast = (str) => {
  if (str && str.length > 0) {
    const tab = str.split("");
    const n = tab.filter((c) => c !== `"`);
    return n.join("");
  }
  return "";
};
export const isAdmin = () => Token.getRole() === "ADMIN";
export const formatToMultiSelectData = (data) => {
  let array = [];
  if (data && data.length > 0) {
    data.forEach((category) => {
      if (category.status && category?.subCategories?.length > 0) {
        category.subCategories.forEach((subcat) => {
          array.push({
            key: subcat.name,
            cat: category?.name,
            subCategoryId: subcat.id,
            categoryId: category?.id,
          });
        });
      }
    });
  }
  return array;
};
export const formatToMultiSelectDataNew = (data) => {
  let array = [];
  if (data && data.length > 0) {
    data.forEach((category) => {
      if (category.status && category?.subCategories?.length > 0) {
        let options = category.subCategories.map((subcat) => {
          return {
            value: subcat.name,
            label: subcat?.name,
            cat: category?.name,
            subCategoryId: subcat.id,
            categoryId: category?.id,
          };
        });
        array.push({
          label: category?.name,
          options,
        });
      }
    });
  }
  return array;
};

export const reverseSubCategoryToCategory = (data) => {
  let parent = data && data.map((cat) => cat.category);
  parent = lodash.uniqBy(parent, "id");

  let cats = parent.map((cat) => {
    let list_sub_categories = [];
    data.map((sub) => {
      if (sub?.category?.id === cat.id) {
        list_sub_categories.push(sub);
      }
    });
    return { id: cat.id, name: cat.name, subCategories: list_sub_categories };
  });
  return cats;
};
export const isAuthentificated = () =>
  localStorage.getItem("token") && Token.getRole() !== null;

export const resizeFile = (file = null) =>
  new Promise((resolve) => {
    if (!file) return resolve(null);

    Resizer.imageFileResizer(
      file,
      800,
      430,
      "JPEG",
      100,
      0,
      (uri) => {
        return resolve(uri);
      },
      "file",
      800,
      400
    );
  });
export const resizeFileWithDimention = (
  file = null,
  height = 150,
  width = 200
) =>
  new Promise((resolve) => {
    if (!file) return resolve(null);

    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      100,
      0,
      (uri) => {
        return resolve(uri);
      },
      "file",
      width,
      height
    );
  });
export const validPrice = (ch) => {
  if (!ch?.length) return true;
  const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", ","];
  let x = ch.replaceAll(",", ".");
  return String(x).split``.every((n) => numbers.includes(n)) && !isNaN(x);
  //return ch?.length === String(+ch).length;
};
export const getPrice = (n) => {
  if (!n) return 0;
  let x = ("" + n).replace(",", ".");
  return parseFloat(x);

  //return ch?.length === String(+ch).length;
};
export const validateVariation = (varis = []) => {
  if (!varis?.length) return false;
  return varis?.every((variant) => {
    return [validPrice(variant?.price), validPrice(variant?.weight)].every(
      Boolean
    );
  });
};
